import styled, { css } from 'styled-components';
import { flexbox, grid, layout, space } from 'styled-system';

import { BoxProps, FlexBoxProps, GridBoxProps } from './types';

export const Box = styled.div<BoxProps>`
  ${space};
  ${layout};
`;

export const GridBox = styled.div<GridBoxProps>`
  display: grid;
  ${grid};
  ${space};
  ${layout};
  ${flexbox};
`;

export const FlexBox = styled.div<FlexBoxProps>`
  display: flex;
  ${space};
  ${flexbox};
  ${layout};
`;

export const innerStyles = css`
  margin: 0 auto;
  width: 100%;
  max-width: calc(70rem + (${({ theme }) => theme.gutters.regular} * 2)); // 1120px + gutters
  padding-left: ${({ theme }) => theme.gutters.regular};
  padding-right: ${({ theme }) => theme.gutters.regular};
`;

export const Inner = styled(Box)`
  ${innerStyles}
`;

export const InnerSmall = styled(Box)`
  margin: 0 auto;
  // innerWidth was previously 628px + gutters, but changed it - 728px + gutters - to unife small inners
  max-width: calc(45.5rem + (${({ theme }) => theme.gutters.regular} * 2));
  padding-left: ${({ theme }) => theme.gutters.regular};
  padding-right: ${({ theme }) => theme.gutters.regular};
`;

export const InnerMedium = styled(Box)`
  margin: 0 auto;
  max-width: calc(75rem + (${({ theme }) => theme.gutters.regular} * 2)); // 1200px + gutters
  padding: 0 ${({ theme }) => theme.gutters.regular};
`;
