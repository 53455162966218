import React from 'react';

import Link from '../../../../atoms/Link';
import { LinkMode } from '../../../../atoms/Link/enums';

import { DecorsSerializerProps } from './types';

const Decors: React.FC<DecorsSerializerProps> = (props) => {
  const { children, mark } = props;
  const { color, link } = mark || {};

  const style = {
    ...(color ? { color } : {}),
  };

  const WrapWithStyles: React.FC = ({ children: content }) => <span {...{ style }}>{content}</span>;

  if (link) {
    const { type, url, resource, media } = link;

    const { slug } = resource || {};
    const { current } = slug || {};

    const { asset } = media || {};
    const { url: mediaUrl } = asset || {};

    if (type === 'external' && url) {
      return (
        <WrapWithStyles>
          <Link mode={LinkMode.HasPlainLook} to={url}>
            {children}
          </Link>
        </WrapWithStyles>
      );
    }

    if (type === 'internal' && current) {
      return (
        <WrapWithStyles>
          <Link mode={LinkMode.HasPlainLook} to={current}>
            {children}
          </Link>
        </WrapWithStyles>
      );
    }

    if (type === 'media' && mediaUrl) {
      return (
        <WrapWithStyles>
          <Link mode={LinkMode.HasPlainLook} to={mediaUrl}>
            {children}
          </Link>
        </WrapWithStyles>
      );
    }

    return null;
  }

  return <WrapWithStyles>{children}</WrapWithStyles>;
};

export default Decors;
