import React from 'react';
import { LazyMotion } from 'framer-motion';

import { SEO } from '../components/SEO';
import * as Styled from './styles';
import { LayoutProps } from './types';
import { useGlobalSettings } from './hooks';

import Header from '../components/organisms/Header';
import Footer from '../components/organisms/Footer';
import CookieBar from '../components/organisms/CookieBar';
import PromoBanner from '../components/organisms/PromoBanner';

const lazyMotionFeatures = () => import('../utils/lazyMotionFeatures').then((res) => res.default);

const Layout: React.FC<LayoutProps> = ({ children, location, data }) => {
  
  const { footer, header, cookieInfo, promoBanner } = useGlobalSettings(data);

  const { sanityContentNotFound } = data ?? {};
  const hideLanguagesDropdown = !!sanityContentNotFound;

  return (
    <LazyMotion strict features={lazyMotionFeatures}>
      <Header data={header} {...{ hideLanguagesDropdown }} />
      <SEO {...{ location } } />
      <Styled.Main>{children}</Styled.Main>
      <Footer data={footer} />
      {cookieInfo && <CookieBar data={cookieInfo} />}
      {promoBanner && <PromoBanner data={promoBanner} />}
    </LazyMotion>
  );
};

export default Layout;
