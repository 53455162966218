import React, { useMemo } from 'react';

import { LinkBaseProps } from './types';
import { localizeTo, tryGetHrefProtocol } from './utils';
import * as Styled from './styles';
import Typography from '../../styles/Typography';
import { useCurrentLocale } from '../../../contexts/LocaleContext/hooks';

const LinkBase: React.FC<LinkBaseProps> = ({
  makeExternalStyles,
  to: rawTo,
  disabled,
  noLocale = false,
  children,
  ...rest
}) => {
  const locale = useCurrentLocale();

  const protocol = tryGetHrefProtocol(rawTo);
  const isExternal = !!protocol;
  const content =
    typeof children === 'string' ? (
      <Typography as="span" variant="textM">
        {children}
      </Typography>
    ) : (
      children
    );

  const externalStyles = useMemo(
    () => makeExternalStyles({ disabled: !!disabled }),
    [disabled, makeExternalStyles]
  );

  const to = isExternal || noLocale ? rawTo : localizeTo(rawTo, locale);

  return (
    <Styled.LinkBase
      {...{
        disabled,
        isExternal,
        externalStyles,
        to,
        ...rest,
      }}
    >
      {content}
    </Styled.LinkBase>
  );
};

export default LinkBase;
