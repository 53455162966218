import React from 'react';

import { BurgerProps } from './types';
import * as Styled from './styles';

import { IconBurgerOpen, IconCloseCircle } from '../../../assets/svg';

const Burger: React.FC<BurgerProps> = ({ isOpen, onClick }) => (
  <Styled.Burger
    {...{ isOpen, onClick }}
    role="button"
    type="button"
    aria-label={isOpen ? 'Close navigation' : 'Open navigation'}
  >
    <Styled.BurgerOpenIcon>
      <IconBurgerOpen />
    </Styled.BurgerOpenIcon>
    <Styled.BurgerCloseIcon>
      <IconCloseCircle />
    </Styled.BurgerCloseIcon>
  </Styled.Burger>
);

export default Burger;
