import theme from '../theme';

const [phone, tablet, desktop, desktopLg] = theme.breakpoints;

const media = {
  phone: `(min-width: ${phone})`,
  tablet: `(min-width: ${tablet})`,
  desktop: `(min-width: ${desktop})`,
  desktopLg: `(min-width: ${desktopLg})`,
  desktopSm: `(min-width: 1279px)`,
  maxPhone: `(max-width: 767px)`,
  maxTablet: `(max-width: 1023px)`,
};

export default media;
