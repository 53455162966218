import React from 'react';

import { elementMap } from './consts';
import * as Styled from './styles';
import { TypographyProps } from './types';

const Typography = React.forwardRef<HTMLElement, TypographyProps>(
  ({ variant = 'textL', as: asProp, children, ...rest }, ref) => {
    const as = asProp || elementMap[variant] || 'p';

    return <Styled.Typography {...{ as, variant, ref, ...rest }}>{children}</Styled.Typography>;
  }
);

export default Typography;
