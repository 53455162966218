import React from 'react';
import { AnimatePresence } from 'framer-motion';

import * as Styled from './styles';
import { LinkMode } from '../../atoms/Link/enums';
import useCookie from '../../../hooks/useCookie';
import { CookieBarProps } from './types';
import RichText from '../../molecules/RichText';

const CookieBar: React.FC<CookieBarProps> = ({ data }) => {
  const { title, desc, label } = data;
  const { onClick, isVisible, hasBannerOnInit } = useCookie('cookie-accept', 'true', 1209600); // 14 days

  return (
    <>
      {hasBannerOnInit ? (
        <AnimatePresence>
          {isVisible && (
            <Styled.CookieBar
              initial={{ opacity: 0, y: '100%' }}
              animate={{
                opacity: 1,
                y: '0',
              }}
              exit={{
                opacity: 0,
                y: '100%',
              }}
              transition={{ duration: 0.28, type: 'tween' }}
            >
              <Styled.CookieBarInner>
                <Styled.CookieBarTitle variant="headingXL">{title}</Styled.CookieBarTitle>
                <Styled.CookieBarDesc>
                  <RichText data={desc} />
                </Styled.CookieBarDesc>
                <Styled.CookieBarAction mode={LinkMode.WithArrowRight} {...{ onClick }}>
                  {label}
                </Styled.CookieBarAction>
              </Styled.CookieBarInner>
            </Styled.CookieBar>
          )}
        </AnimatePresence>
      ) : null}
    </>
  );
};

export default CookieBar;
