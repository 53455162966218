import { GatsbyImageProps, IGatsbyImageData } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { ImageNode } from 'gatsby-source-sanity/lib-es5/images/getGatsbyImageProps';

export const getHref = (link: GatsbyTypes.Maybe<GatsbyTypes.LinkFragmentFragment>): string => {
  if (!link) {
    return '';
  }

  const { type } = link;

  if (type === 'external') {
    return link.url ?? '';
  }

  if (type === 'media') {
    return link.media?.asset?.url ?? '';
  }

  return link.resource?.slug?.current ?? '';
};

export const getImage = (
  image: GatsbyTypes.Maybe<GatsbyTypes.ImageFragmentFragment>
): GatsbyImageProps => ({
  alt: image?.alt ?? '',
  image: image?.asset?.gatsbyImageData as unknown as IGatsbyImageData,
});

const sanityLocation = {
  dataset: process.env.GATSBY_SANITY_DATASET as string,
  projectId: process.env.GATSBY_SANITY_PROJECT_ID as string,
};

export const mapSanityImageToGatsbyImage = (
  image: GatsbyTypes.Maybe<GatsbyTypes.SanityImageWithAlt>
): GatsbyImageProps => {
  const { alt = '' } = image ?? {};

  return {
    alt,
    image: getGatsbyImageData(
      image as ImageNode,
      {
        placeholder: 'blurred',
      },
      sanityLocation
    ) as IGatsbyImageData,
  };
};
