import React from 'react';

import { DropdownProps } from './types';
import * as Styled from './styles';

import { IconArrowDown, IconTickCircle } from '../../../assets/svg';
import { LinkMode } from '../../atoms/Link/enums';
import useToggle from '../../../hooks/useToggle';

const Dropdown: React.FC<DropdownProps> = ({ data, noLocale }) => {
  const [isOpen, { toggle, registerContainerRef }] = useToggle();
  const selectedText = data.find(({ isSelected }) => isSelected)?.label;

  return (
    <Styled.Dropdown {...{ isOpen }} ref={registerContainerRef}>
      <Styled.DropdownHead onClick={toggle}>
        <Styled.DropdownLabel variant="textM">{selectedText}</Styled.DropdownLabel>
        <Styled.DropdownIcon>
          <IconArrowDown />
        </Styled.DropdownIcon>
      </Styled.DropdownHead>
      <Styled.DropdownBody>
        <Styled.DropdownList>
          {data.map(({ label, value, isSelected, url }) => (
            <Styled.DropdownListItem key={value} {...{ value, isSelected }}>
              <Styled.DropdownLink mode={LinkMode.HasPlainLook} to={url} {...{ noLocale }}>
                <Styled.DropdownLabel variant="textM">{label}</Styled.DropdownLabel>
                <Styled.DropdownTick>
                  <IconTickCircle />
                </Styled.DropdownTick>
              </Styled.DropdownLink>
            </Styled.DropdownListItem>
          ))}
        </Styled.DropdownList>
      </Styled.DropdownBody>
    </Styled.Dropdown>
  );
};

export default Dropdown;
