import { ElementMapType } from './types';

// TODO - change tag elements
export const elementMap: ElementMapType = {
  headingS: 'h5',
  headingM: 'h4',
  headingL: 'h3',
  headingXL: 'h2',
  headingXXL: 'h1',
};
