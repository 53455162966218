import { CSSProperties } from 'react';
import { TextAlignProps } from 'styled-system';

import theme from '../../../../../theme';

export const mergeTextAlignProp = (
  blockTextAlign: CSSProperties['textAlign'] | undefined,
  textAlign: TextAlignProps['textAlign']
): TextAlignProps['textAlign'] => {
  if (!textAlign || !blockTextAlign) {
    return textAlign || blockTextAlign;
  }

  if (!Array.isArray(textAlign)) {
    return textAlign;
  }

  return Array.from(
    { length: theme.breakpoints.length },
    (_, index) => textAlign[index] || blockTextAlign
  );
};
