import urlJoin from 'url-join';
import { API_URL } from './src/utils/enviroments.ts';

import localization from './src/localization/index.ts';

export { wrapRootElement } from './src/components/wrapRootElement.tsx';
export { wrapPageElement } from './src/components/wrapPageElement.tsx';

const wakeHeroku = () => {
  const url = new URL(urlJoin(API_URL, 'posts/search'));

  url.searchParams.append('query', '');
  url.searchParams.append('page', 1);
  url.searchParams.append('lang', localization.base);

  fetch(url.toString()).catch(() => {
    // Do nothing
  });
};

export const onClientEntry = async () => {
  window.addEventListener('scroll', wakeHeroku, {
    once: true,
  });
  document.addEventListener('mouseover', wakeHeroku, {
    once: true,
  });
};
