import React from 'react';
import { GatsbyBrowser } from 'gatsby';
import Layout from '../layouts';
import GlobalStyles from '../theme/GlobalStyles';
import LocaleContextProvider from '../contexts/LocaleContext/provider';
import PagePropsContext from '../contexts/PageDataContext';
import localization from '../localization';
import { WEB_PREVIEW_PATHNAME } from '../utils/consts';

type WrapPageElementProps = GatsbyBrowser['wrapPageElement'];

export const wrapPageElement: WrapPageElementProps = ({ element, props }) => {
  const withLayout = props.location.pathname !== WEB_PREVIEW_PATHNAME;

  return (
    <>
      <GlobalStyles />
      <LocaleContextProvider
        locale={
          typeof props?.pageContext?.language === 'string'
            ? props?.pageContext?.language
            : localization.base
        }
      >
        <PagePropsContext.Provider value={props.data}>
          {withLayout ? <Layout {...props}>{element}</Layout> : element}
        </PagePropsContext.Provider>
      </LocaleContextProvider>
    </>
  );
};
