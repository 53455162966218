import React from 'react';

import * as Styled from './styles';
import RichText from '../RichText';
import { InfoTileProps } from './types';

const InfoTile: React.FC<InfoTileProps> = ({ title, desc }) => (
  <Styled.InfoTile>
    <Styled.InfoTileTitle variant="textS">{title}</Styled.InfoTileTitle>
    {desc && (
      <Styled.InfoTileDesc>
        <RichText data={desc} />
      </Styled.InfoTileDesc>
    )}
  </Styled.InfoTile>
);

export default InfoTile;
