import { css } from 'styled-components';

export const transition = (property = 'all', duration = '150ms') => css`
  transition: ${property} ${duration} ease;
`;

export const positionFullStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;
