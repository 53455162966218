import { useEffect, useState } from 'react';

import ScrollDirections from '../enums/scroll';
import useScrollDirection from './useScrollDirection';

const usePageScrolled = () => {
  const [isPageScrolled, setIsPageScrolled] = useState(false);
  const scrollDirection = useScrollDirection();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (scrollDirection === ScrollDirections.SCROLL_UP) {
        setIsPageScrolled(false);
      } else if (scrollDirection === ScrollDirections.SCROLL_DOWN && window.scrollY >= 30) {
        setIsPageScrolled(true);
      }
    }
  }, [scrollDirection]);

  return isPageScrolled;
};

export default usePageScrolled;
