import { useEffect, useState } from 'react';

import ScrollDirections from '../enums/scroll';

type UseScrollDirectionParams = {
  initialDirection?: ScrollDirections;
  thresholdPixels?: number;
};

const useScrollDirection = ({
  initialDirection = ScrollDirections.SCROLL_UP,
  thresholdPixels = 64,
}: UseScrollDirectionParams = {}) => {
  const [scrollDir, setScrollDir] = useState(initialDirection);

  useEffect(() => {
    const threshold = thresholdPixels || 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        // We haven't exceeded the threshold
        ticking = false;
        return;
      }

      setScrollDir(
        scrollY > lastScrollY ? ScrollDirections.SCROLL_DOWN : ScrollDirections.SCROLL_UP
      );
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [initialDirection, thresholdPixels]);

  return scrollDir;
};

export default useScrollDirection;
