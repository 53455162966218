import { useCallback, useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';

const useCookie = (cookieName: string, cookieValue: string, cookieAge: number) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [cookies, setCookie] = useCookies([cookieName]);
  const hasBannerOnInitRef = useRef<boolean>(true);
  const hasBannerOnInit = hasBannerOnInitRef.current;

  useEffect(() => {
    hasBannerOnInitRef.current = cookies[`${cookieName}`] !== cookieValue;
    setIsVisible(cookies[`${cookieName}`] !== cookieValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = useCallback(() => {
    setCookie(cookieName, cookieValue, { path: '/', maxAge: cookieAge });
    setIsVisible(false);
  }, [cookieName, cookieValue, cookieAge, setCookie]);

  return { onClick, isVisible, hasBannerOnInit };
};

export default useCookie;
