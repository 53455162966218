import { useContext } from 'react';
import { useLocation } from '@reach/router';

import LocaleContext from '.';
import localization from '../../localization';

export const useCurrentLocale = (): string => {
  const { locale } = useContext(LocaleContext);

  return locale;
};

export const usePathnameWithoutLocale = (): string => {
  const { pathname } = useLocation();
  const locale = useCurrentLocale();

  return locale === localization.base ? pathname : pathname.replace(`/${locale}`, '');
}
