import React, { useMemo } from 'react';
import BlockContent from '@sanity/block-content-to-react';

import { RichTextProps } from './types';
import * as Styled from './styles';
import { serializers } from './consts';

const RichText: React.FC<RichTextProps> = ({
  data,
  elementsMargin = '1.5rem',
  hasDarkBackground,
  textAlign,
}) => {
  const serializersDefs = useMemo(
    () => serializers({ textAlign }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(textAlign)]
  );

  return (
    <Styled.RichTextContainer {...{ elementsMargin, hasDarkBackground }}>
      <BlockContent blocks={data} serializers={serializersDefs} />
    </Styled.RichTextContainer>
  );
};

export default RichText;
