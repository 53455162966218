export const GATSBY_MAP_TOKEN = (() => {
  if (!process.env.GATSBY_MAP_TOKEN) {
    throw new Error('Missing environment variable - GATSBY_MAP_TOKEN');
  }

  return process.env.GATSBY_MAP_TOKEN;
})();

export const GATSBY_MAP_STYLE = (() => {
  if (!process.env.GATSBY_MAP_STYLE) {
    throw new Error('Missing environment variable - GATSBY_MAP_STYLE');
  }

  return process.env.GATSBY_MAP_STYLE;
})();

export const SENDGRID_API_KEY = (() => {
  if (!process.env.SENDGRID_API_KEY && typeof window === 'undefined') {
    throw new Error('Missing environment variable - SENDGRID_API_KEY');
  }

  return process.env.SENDGRID_API_KEY;
})();

export const SENDGRID_EMAIL = (() => {
  if (!process.env.SENDGRID_EMAIL && typeof window === 'undefined') {
    throw new Error('Missing environment variable - SENDGRID_EMAIL');
  }

  return process.env.SENDGRID_EMAIL;
})();

export const API_URL = (() => {
  if (!process.env.GATSBY_API_URL) {
    throw new Error('Missing environment variable - GATSBY_API_URL');
  }

  return process.env.GATSBY_API_URL;
})();

export const GATSBY_SANITY_PROJECT_ID = (() => {
  if (!process.env.GATSBY_SANITY_PROJECT_ID && typeof window === 'undefined') {
    throw new Error('Missing environment variable - GATSBY_SANITY_PROJECT_ID');
  }

  return process.env.GATSBY_SANITY_PROJECT_ID;
})();

export const GATSBY_SANITY_DATASET = (() => {
  if (!process.env.GATSBY_SANITY_DATASET && typeof window === 'undefined') {
    throw new Error('Missing environment variable - GATSBY_SANITY_DATASET');
  }

  return process.env.GATSBY_SANITY_DATASET;
})();
