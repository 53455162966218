// @generated
// This file was automatically generated and should not be edited.

const localization = {
  base: 'en',
  languages: [
    { name: 'en', title: 'English' },
    { name: 'no', title: 'Norsk' },
  ],
  fieldNames: { lang: 'i18n_lang', references: 'i18n_refs' },
};

export default localization;
