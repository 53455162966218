import React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { FooterProps } from './types';
import * as Styled from './styles';

import { LogoLyngenNorth } from '../../../assets/svg';
import Nav from '../../molecules/Nav';
import InfoTile from '../../molecules/InfoTile';
import Link from '../../atoms/Link';
import { LinkMode } from '../../atoms/Link/enums';
import Typography from '../../styles/Typography';
import Socials from '../../molecules/Socials';

const Footer: React.FC<FooterProps> = ({ data }) => {
  const {
    logoUrl: { to: logoUrl },
    menu,
    location,
    contact,
    terms,
    extractedButton,
    copyrights,
    socialsLabel,
    socials,
  } = data;
  const breakpoints = useBreakpoint();

  return (
    <Styled.Footer>
      <Styled.FooterTop
        pt={breakpoints.tablet ? '6.75rem' : '4rem'}
        pb={breakpoints.tablet ? '5rem' : '3rem'}
      >
        <Styled.FooteTopInner>
          <Styled.FooterLogo to={logoUrl} mode={LinkMode.HasPlainLook} aria-label="logo">
            <LogoLyngenNorth />
          </Styled.FooterLogo>
          <Styled.FooterMenu>
            {breakpoints.tablet && (
              <Styled.FooterNav>
                <Nav items={menu} isAlt />
              </Styled.FooterNav>
            )}
            {extractedButton && (
              <Styled.FooterAction>
                <Link to={extractedButton.link}>{extractedButton.label}</Link>
              </Styled.FooterAction>
            )}
          </Styled.FooterMenu>
          {location && (
            <Styled.FooterLocation>
              <InfoTile title={location.title} desc={location.desc} />
            </Styled.FooterLocation>
          )}
          {contact && (
            <Styled.FooterContact>
              <Styled.FooterContactList>
                {contact.map(({ title, desc, key }) => (
                  <Styled.FooterContactItem {...{ key }}>
                    <InfoTile {...{ title, desc }} />
                  </Styled.FooterContactItem>
                ))}
              </Styled.FooterContactList>
            </Styled.FooterContact>
          )}
        </Styled.FooteTopInner>
      </Styled.FooterTop>
      <Styled.FooterBottom pt={['2.5rem']} pb={breakpoints.tablet ? '5rem' : '2.5rem'}>
        <Styled.FooterBottomInner>
          {terms && (
            <Styled.FooterTerms>
              <Styled.FooterTermsLink to={terms.link} mode={LinkMode.HasPlainLook}>
                <Typography variant="textS">{terms.label}</Typography>
              </Styled.FooterTermsLink>
            </Styled.FooterTerms>
          )}
          {copyrights && (
            <Styled.FooterPrivacy>
              <Styled.FooterPrivacyDesc>
                <Typography variant="textS">{copyrights}</Typography>
              </Styled.FooterPrivacyDesc>
            </Styled.FooterPrivacy>
          )}
          {!!socials?.length && socialsLabel && (
            <Styled.FooterSocials>
              <Styled.FooterSocialsLabel variant="textS">{socialsLabel}</Styled.FooterSocialsLabel>
              <Styled.FooterSocialsList>
                <Socials items={socials} />
              </Styled.FooterSocialsList>
            </Styled.FooterSocials>
          )}
        </Styled.FooterBottomInner>
      </Styled.FooterBottom>
    </Styled.Footer>
  );
};

export default Footer;
