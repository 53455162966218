import styled, { css } from 'styled-components';

import { BurgerStyledProps } from './types';
import { transition } from '../../../common/mixins';

const burgerIconStyles = css`
  ${transition()}
  position: absolute;
  display: inline-flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 1.75rem;
`;

export const BurgerOpenIcon = styled.span`
  ${burgerIconStyles}
  color: ${({ theme }) => theme.colors.neutral.neutral10};
`;

export const BurgerCloseIcon = styled.span`
  ${burgerIconStyles}
  color: ${({ theme }) => theme.colors.additional.accentGray4};
  opacity: 0;
`;

export const Burger = styled.button<BurgerStyledProps>`
  position: relative;
  height: 1.75rem;
  width: 1.75rem;
  background-color: transparent;
  padding: 0;
  border: 0;

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${BurgerOpenIcon} {
        opacity: 0;
      }
      ${BurgerCloseIcon} {
        opacity: 1;
      }
    `}
`;
