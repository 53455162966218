import React from 'react';

import { BlockContentProps } from '@sanity/block-content-to-react';

import Block from './serializers/block';
import Color from './serializers/color';
import Decors from './serializers/decors';
import { AdditionalRichTextProps } from './types';

export const serializers = (
  additionalProps: AdditionalRichTextProps
): BlockContentProps['serializers'] => ({
  marks: {
    color: (props): JSX.Element => <Color {...{ ...props, additionalProps }} />,
    decors: (props): JSX.Element => <Decors {...{ ...props, additionalProps }} />,
  },
  types: {
    block: (props): JSX.Element => <Block {...{ ...props, additionalProps }} />,
  },
});
