import urlJoin from 'url-join';
import localization from '../../../localization';

export const tryGetHrefProtocol = (href: string): string | undefined => {
  try {
    return new URL(href).protocol;
  } catch (_) {
    return undefined;
  }
};

export const localizeTo = (to: string, locale: string) => {
  if (locale === localization.base) {
    return to;
  }

  return urlJoin('', locale, to);
};
