import React from 'react';
import { AnimatePresence } from 'framer-motion';

import * as Styled from './styles';
import { PromoBannerProps } from './types';

import { IconCloseCircle } from '../../../assets/svg';
import Image from '../../atoms/Image';
import { LinkMode } from '../../atoms/Link/enums';
import useCookie from '../../../hooks/useCookie';
import RichText from '../../molecules/RichText';

const PromoBanner: React.FC<PromoBannerProps> = ({ data }) => {
  const { image, title, desc, button, isActive } = data;
  const { onClick, isVisible, hasBannerOnInit } = useCookie('promo', 'true', 604800); // 7 days

  return (
    <>
      {hasBannerOnInit && isActive ? (
        <AnimatePresence>
          {isVisible && (
            <Styled.PromoBanner
              initial={{ opacity: 0, y: '100%' }}
              animate={{
                opacity: 1,
                y: '0',
              }}
              exit={{
                opacity: 0,
                y: '100%',
              }}
              transition={{ duration: 0.28, type: 'tween' }}
            >
              <Styled.PromoBannerInner>
                <Styled.PromoBannerContentBox>
                  <Styled.PromoBannerClose mode={LinkMode.HasPlainLook} {...{ onClick }}>
                    <IconCloseCircle />
                  </Styled.PromoBannerClose>
                  {image && (
                    <Styled.PromoBannerMedia>
                      <Image {...image} loading="eager" />
                    </Styled.PromoBannerMedia>
                  )}
                  <Styled.PromoBannerContent>
                    <Styled.PromoBannerTitle variant="headingM">{title}</Styled.PromoBannerTitle>
                    {desc && (
                      <Styled.PromoBannerSubitle variant="textL">
                        <RichText data={desc} />
                      </Styled.PromoBannerSubitle>
                    )}
                    <Styled.PromoBannerAction
                      to={button.to}
                      mode={LinkMode.WithArrowRight}
                      {...{ onClick }}
                    >
                      {button?.label}
                    </Styled.PromoBannerAction>
                  </Styled.PromoBannerContent>
                </Styled.PromoBannerContentBox>
              </Styled.PromoBannerInner>
            </Styled.PromoBanner>
          )}
        </AnimatePresence>
      ) : null}
    </>
  );
};

export default PromoBanner;
