import { useState, useCallback, useEffect } from 'react';
import pullAt from 'lodash/pullAt';

interface RefRegistryEntry {
  element: HTMLElement;
  toggleOff: () => void;
  toggleState: boolean;
}

interface ToggleProps {
  defaultToggleState?: boolean;
  onToggleOn?: () => void;
  onToggleOff?: () => void;
}

export type UseToggleReturnType = [
  boolean,
  {
    toggle: () => void;
    toggleOn: () => void;
    toggleOff: () => void;
    registerContainerRef: (ref: HTMLElement | null) => void;
  }
];

let documentClickHandlerRegistered = false;
const refsRegistry: RefRegistryEntry[] = [];
const documentClickHander = (event: MouseEvent): void => {
  refsRegistry.forEach(({ element, toggleOff, toggleState }) => {
    if (toggleState && !element.contains(event.target as Node)) {
      toggleOff();
    }
  });
};

const useToggle = ({
  onToggleOn,
  onToggleOff,
  defaultToggleState = false,
}: ToggleProps = {}): UseToggleReturnType => {
  const [toggleState, setToggleState] = useState(defaultToggleState);
  const toggle = useCallback(() => {
    setTimeout(() => {
      setToggleState((currentToggleState) => {
        const newState = !currentToggleState;

        if (newState) {
          onToggleOn?.();
        } else {
          onToggleOff?.();
        }

        return newState;
      });
    });
  }, [onToggleOff, onToggleOn]);
  const toggleOff = useCallback(() => {
    setTimeout(() => {
      setToggleState(false);
      onToggleOff?.();
    });
  }, [onToggleOff]);
  const toggleOn = useCallback(() => {
    setTimeout(() => {
      setToggleState(true);
      onToggleOn?.();
    });
  }, [onToggleOn]);

  useEffect(() => {
    if (!documentClickHandlerRegistered) {
      documentClickHandlerRegistered = true;
      document.addEventListener('click', documentClickHander);
    }
  }, []);

  const registerContainerRef = useCallback(
    (ref: HTMLElement | null): void => {
      const currentEntryIndex = refsRegistry.findIndex(
        ({ toggleOff: entryToggleOff }) => entryToggleOff === toggleOff
      );

      if (!ref && currentEntryIndex >= 0) {
        pullAt(refsRegistry, currentEntryIndex);

        return;
      }

      if (ref && currentEntryIndex < 0) {
        refsRegistry.push({
          element: ref,
          toggleOff,
          toggleState,
        });
      }
    },
    [toggleOff, toggleState]
  );

  return [toggleState, { toggle, toggleOn, toggleOff, registerContainerRef }];
};

export default useToggle;
