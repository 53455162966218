import styled from 'styled-components';

import media from '../../../common/MediaQueries';
import { transition } from '../../../common/mixins';
import { Inner, Box } from '../../styles/Grid/styles';
import { LogoStyles } from '../../styles/common';
import Link from '../../atoms/Link';
import Typography from '../../styles/Typography';

export const Footer = styled.div``;

export const FooterTop = styled(Box)`
  background-color: ${({ theme }) => theme.colors.additional.accentGray3};
`;

export const FooteTopInner = styled(Inner)`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  justify-content: space-between;
`;

export const FooterMenu = styled.div`
  display: flex;
  align-items: center;

  grid-column: 2/2;
  grid-row: 1/2;
`;

export const FooterLogo = styled(Link)`
  ${transition()}
  ${LogoStyles}

  grid-column: 1/2;
  grid-row: 1/2;

  color: ${({ theme }) => theme.colors.additional.dark};

  @media ${media.tablet} {
    &:hover {
      color: ${({ theme }) => theme.colors.primary.pressed};
    }
  }
`;

export const FooterNav = styled.div``;

export const FooterAction = styled.div`
  padding-left: 1.5rem;
  margin-left: auto;
  @media ${media.tablet} {
    padding-left: 0;
    margin-left: 6rem;
  }
`;

export const FooterLocation = styled.div`
  grid-column: 1/2;
  grid-row: 2/2;
  margin-right: 2.75rem;
  margin-top: 4rem;
  max-width: 7.5rem;
  @media ${media.tablet} {
    margin-top: 6.75rem;
  }
`;

export const FooterContact = styled.div`
  grid-column: 2/2;
  grid-row: 2/2;
  margin-top: 4rem;
  @media ${media.tablet} {
    margin-top: 6.75rem;
  }
`;
export const FooterContactList = styled.ul`
  @media ${media.tablet} {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 25rem;
  }
`;

export const FooterContactItem = styled.li`
  &:not(:first-child) {
    margin-top: 1rem;
  }
  @media ${media.tablet} {
    &:not(:first-child) {
      margin-top: 0;
      margin-left: 1.5rem;
    }
  }
`;

export const FooterBottom = styled(Box)`
  color: ${({ theme }) => theme.colors.additional.accentGray2};
`;

export const FooterBottomInner = styled(Inner)`
  display: flex;
  flex-direction: column;
  @media ${media.tablet} {
    flex-direction: row;
  }
`;

export const FooterTerms = styled.div`
  order: 1;
  margin-top: 2.5rem;
  @media ${media.tablet} {
    order: 0;
    margin-top: 0;
  }
`;

export const FooterTermsLink = styled(Link)`
  @media ${media.tablet} {
    ${transition()}
    &:hover {
      color: ${({ theme }) => theme.colors.primary.pressed};
    }
  }
`;

export const FooterPrivacy = styled.div`
  max-width: 30rem;
  margin-top: 1rem;
  order: 2;
  @media ${media.tablet} {
    margin-left: auto;
    margin-top: 0;
    order: 1;
  }
`;

export const FooterPrivacyDesc = styled.div`
  display: inline-block;
`;

export const FooterSocials = styled.div`
  display: flex;
  order: 0;
  min-width: 11rem;
  @media ${media.tablet} {
    margin-left: 5.5rem;
    order: 2;
  }
`;

export const FooterSocialsLabel = styled(Typography)``;

export const FooterSocialsList = styled.div`
  margin-left: 1.75rem;
`;
