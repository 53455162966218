import React from 'react';

import * as Styled from './styles';
import { SocialMode } from './enums';
import { SocialsProps } from './types';
import { socialLinkSwitch } from './consts';
import { useCopyClick } from './hooks';

import { LinkMode } from '../../atoms/Link/enums';
import {
  IconSocialTwitter,
  IconSocialFacebook,
  IconSocialInstagram,
  IconSocialShare,
} from '../../../assets/svg';

const Socials: React.FC<SocialsProps> = ({ items, isShare }) => {
  const { isCopied, onClick } = useCopyClick();

  return (
    <Styled.SocialsList>
      {items?.map(({ to, iconMode, key }) => (
        <Styled.SocialsListItem {...{ key }}>
          <Styled.SocialsLink
            to={isShare ? socialLinkSwitch(iconMode, to) : to}
            mode={LinkMode.HasPlainLook}
            aria-label={iconMode}
            hasOverlayMask
            onClick={iconMode === SocialMode.Copy ? onClick : undefined}
          >
            {iconMode === SocialMode.Instagram && <IconSocialInstagram />}
            {iconMode === SocialMode.Facebook && <IconSocialFacebook />}
            {iconMode === SocialMode.Twitter && <IconSocialTwitter />}
            {iconMode === SocialMode.Copy && <IconSocialShare />}
          </Styled.SocialsLink>
          {isCopied && iconMode === SocialMode.Copy && (
            <Styled.SocialsLinkPopup variant="textS">Link copied!</Styled.SocialsLinkPopup>
          )}
        </Styled.SocialsListItem>
      ))}
    </Styled.SocialsList>
  );
};

export default Socials;
