import styled from 'styled-components';

import Typography from '../../styles/Typography';
import media from '../../../common/MediaQueries';

export const InfoTile = styled.div``;

export const InfoTileTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.additional.accentGray2};
  margin-bottom: 1rem;

  @media ${media.phone} {
    margin-bottom: 1.5rem;
  }
`;

export const InfoTileDesc = styled.div`
  p,
  a {
    ${({ theme }) => theme.textS};
    color: ${({ theme }) => theme.colors.additional.dark};
  }

  span > a {
    background-size: 0% 1px;
  }
  span > a:hover {
    background-size: 100% 1px;
  }
`;
