import React, { useEffect } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { HeaderProps } from './types';
import * as Styled from './styles';

import { LogoLyngenNorth } from '../../../assets/svg';
import Nav from '../../molecules/Nav';
import Dropdown from '../../molecules/Dropdown';
import Burger from '../../molecules/Burger';
import { LinkMode } from '../../atoms/Link/enums';
import usePageScrolled from '../../../hooks/usePageScrolled';
import useToggle from '../../../hooks/useToggle';
import { useCurrentLocale, usePathnameWithoutLocale } from '../../../contexts/LocaleContext/hooks';
import localization from '../../../localization';
import { bodyClassName } from './consts';

const Header: React.FC<HeaderProps> = ({ data, hideLanguagesDropdown }) => {
  const { logoUrl, menu, extractedButton, mode } = data;
  const [toggleState, { toggle, toggleOff }] = useToggle({
    onToggleOn: () => {
      document.body.classList.add(bodyClassName.overflowHidden);
    },
    onToggleOff: () => {
      document.body.classList.remove(bodyClassName.overflowHidden);
    },
  });
  const isPageScrolled = usePageScrolled();
  const breakpoints = useBreakpoint();
  const pathname = usePathnameWithoutLocale();
  const locale = useCurrentLocale();

  const languages = localization.languages.map((lang) => ({
    label: lang.title,
    isSelected: lang.name === locale,
    url: lang.name === localization.base ? pathname : `/${lang.name}${pathname}`,
    value: lang.name === localization.base ? pathname : `/${lang.name}${pathname}`,
  }));

  useEffect(() => {
    if (extractedButton) {
      document.body.classList.add(bodyClassName.gutterBottom);
    }
  }, [extractedButton]);

  return (
    <>
      <Styled.Header {...{ isPageScrolled, mode }} isOpen={toggleState} role="banner">
        <Styled.HeaderInner>
          <Styled.HeaderContent>
            <Styled.HeaderTop>
              <Styled.HeaderLogo to={logoUrl.to} mode={LinkMode.HasPlainLook} aria-label="logo">
                <LogoLyngenNorth />
              </Styled.HeaderLogo>
              <Styled.HeaderBurger>
                <Burger isOpen={toggleState} onClick={toggle} />
              </Styled.HeaderBurger>
            </Styled.HeaderTop>
            <Styled.HeaderNavBox>
              <Styled.HeaderNav data-hidden={!toggleState}>
                <Nav items={menu} onClick={toggleOff} />
              </Styled.HeaderNav>
              {languages && (
                <Styled.HeaderDropdown hide={hideLanguagesDropdown}>
                  <Dropdown data={languages} noLocale />
                </Styled.HeaderDropdown>
              )}
              {extractedButton && (
                <Styled.HeaderAction>
                  <Styled.HeaderActionLink to={extractedButton.link} onClick={toggleOff}>
                    {extractedButton.label}
                  </Styled.HeaderActionLink>
                </Styled.HeaderAction>
              )}
            </Styled.HeaderNavBox>
          </Styled.HeaderContent>
        </Styled.HeaderInner>
      </Styled.Header>
      {extractedButton && !breakpoints.tablet && (
        <Styled.HeaderStickyAction>
          <Styled.HeaderActionLink to={extractedButton.link}>
            {extractedButton.label}
          </Styled.HeaderActionLink>
        </Styled.HeaderStickyAction>
      )}
    </>
  );
};

export default Header;
