import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';
import { SEOProps } from './types';

export const SEO: FunctionComponent<SEOProps> = ({ location, pageMetadata }) => {

  const {
    title,
    description,
    keywords,
    siteUrl = location?.host,
    imageUrl,
    language,
  } = useSiteMetadata();

  return (
    <Helmet title={pageMetadata?.title} defaultTitle={title} titleTemplate={`${title} - %s`}>
      <html lang={language} />

      <meta property="og:url" content={`${siteUrl}${location?.pathname}`} />
      <meta property="og:type" content='website' />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="format-detection" content="telephone=no" />

      {/*
        These properties below will be updated in components/templates/page/index.tsx
        If the page does not have specified SEO data, it will default to the siteMetaData in gatsby-config.js
      */}
      <meta property="og:title" content={title} />
      <meta name="keywords" content={keywords} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${siteUrl}${imageUrl}`} />
      <meta property="og:image:alt" content={title} />
      
    </Helmet>
  );
};
