import colors from './colors';
import fontWeights from './fontWeights';
import text from './text';

const theme = {
  ...colors,
  ...text,
  breakpoints: ['768px', '1024px', '1280px', '1440px'],
  fontWeights,
  spacing: {
    noGutter: ['0', '0'],
    tiny: ['1.25rem'],
    extraSmall: ['2.5rem'],
    small: ['3rem', '5rem'],
    regular: ['5rem', '7.5rem'],
    medium: ['5rem', '10rem'],
    large: ['7.5rem', '11.25rem'],
  },
  gutters: {
    regular: '1.5rem',
  },
  shadows: {
    outline: 'box-shadow: 0 1px 2px 0 #0000001F;',
    main: 'box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.1);',
    border: 'box-shadow: 0px 1px 0px rgba(243, 243, 243, 0.2);',
    insetOutline: 'box-shadow: inset 0px -1px 0px rgba(28, 28, 28, 0.1);',
    insetBorder: 'box-shadow: inset 0px -2px 0px #1D1D1D;',
    focus: 'box-shadow: 0px 0px 4px -1px #292D32;',
    banner: 'box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 5%);',
  },
  overlays: {
    radial30: ' background: radial-gradient(rgba(0, 0, 0, 0.3), transparent);',
    negativeRadial30:
      'background: radial-gradient(rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 30%)',
    radial70: ' background: radial-gradient(rgba(0, 0, 0, 0.7), transparent);',
    radial80: ' background: radial-gradient(rgba(0, 0, 0, 0.8), transparent);',
    linear20: 'background: linear-gradient(0deg, transparent, rgba(10, 10, 10, 0.2));',
    linear50: 'background: linear-gradient(180deg, transparent 50%, rgba(0, 0, 0, 0.5) 100%);',
  },
  fonts: {
    primary: `'TT Firs Neue', system-ui, 'sans-serif'`,
  },
  zIndexes: {
    modal: 30,
    header: 20,
    headerAction: 10,
    headerNav: 3,
    headerTop: 2,
  },
  heights: {
    mobileWindow: 'calc(100vh - 5rem)',
  },
};

export default theme;

export type Theme = typeof theme;
