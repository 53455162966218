export const numberToTwoDigits = (value: number) => {
  const valueTwoDigits = value.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  return valueTwoDigits;
};

export const WEB_PREVIEW_PATHNAME = '/web__preview';
