import { SocialMode } from './enums';

export const FACEBOOK_SHARE_URL = 'https://www.facebook.com/sharer/sharer.php';
export const TUMBLR_SHARE_URL = 'http://tumblr.com/widgets/share/tool';

export const socialLinkSwitch = (param: SocialMode | null, to: string) => {
  switch (param) {
    case SocialMode.Copy:
      return typeof window !== 'undefined' ? `${window.location}` : '#';
    case SocialMode.Facebook:
      return `https://www.facebook.com/sharer/sharer.php?u=${to}`;
    case SocialMode.Twitter:
      return `https://twitter.com/share?url=${to}`;
    case SocialMode.Instagram:
      return `https://www.instagram.com/?url=${to}`;
    default:
      return '';
  }
};
