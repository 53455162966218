import React from 'react';
import LocaleContext from '.';

import { LocaleContextProviderProps } from './types';

const LocaleContextProvider: React.FC<LocaleContextProviderProps> = ({ children, locale }) => (
  <LocaleContext.Provider
    value={{
      locale,
    }}
  >
    {children}
  </LocaleContext.Provider>
);

export default LocaleContextProvider;
