import styled from 'styled-components';

import Link from '../../atoms/Link';
import { transition } from '../../../common/mixins';
import Typography from '../../styles/Typography';

export const SocialsList = styled.ul`
  display: flex;
`;

export const SocialsLink = styled(Link)`
  ${transition()}
  position: relative;
  display: inline-flex;
  color: ${({ theme }) => theme.colors.secondary.hover};
  font-size: 1rem;

  &:before {
    margin: -0.25rem;
  }
`;

export const SocialsListItem = styled.li`
  position: relative;
  display: inline-flex;

  &:not(:first-child) {
    margin-left: 1.25rem;
  }
  &:hover ${SocialsLink} {
    transform: scale(1.2);
  }
`;

export const SocialsLinkPopup = styled(Typography)`
  position: absolute;
  bottom: 1.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  white-space: nowrap;
  background-color: ${({ theme }) => theme.colors.neutral.neutral20};
  ${({ theme }) => theme.shadows.outline}
`;
