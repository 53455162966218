import styled, { css } from 'styled-components';

import Link from '../../atoms/Link';
import { NavItemStyledProps, NavStyledProps } from './types';

import media from '../../../common/MediaQueries';
import { transition } from '../../../common/mixins';
import Typography from '../../styles/Typography';

const navItemActiveStyles = css`
  left: 0;
  right: 0;
  opacity: 1;
`;

export const NavTitle = styled(Typography)``;

export const NavLink = styled(Link)`
  padding: 2.5rem 0;
  color: ${({ theme }) => theme.colors.neutral.neutral10};

  @media ${media.maxTablet} {
    width: 100%;
    color: ${({ theme }) => theme.colors.additional.dark};
  }

  @media ${media.tablet} {
    padding: 2.5rem 0.5rem;
  }

  @media ${media.desktopSm} {
    padding: 2.5rem 1rem;
  }
`;

export const NavItem = styled.li<NavItemStyledProps>`
  position: relative;

  &::after {
    ${transition()}
    position: absolute;
    content: '';
    height: 1px;
    bottom: 0;
    left: 50%;
    right: 50%;
    opacity: 0;
    background-color: ${({ theme }) => theme.colors.additional.dark};
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      @media ${media.maxTablet} {
        opacity: 0.8;
        ${NavLink} {
          color: ${theme.colors.secondary.hover};
        }
      }
      &::after {
        ${navItemActiveStyles}
      }
    `}

  @media ${media.maxTablet} {
    &::after {
      ${navItemActiveStyles}
    }
  }

  @media ${media.tablet} {
    &:not(:first-child) {
      margin-left: 0.25rem;
    }
    &::after {
      background-color: ${({ theme }) => theme.colors.neutral.neutral10};
    }
    &:hover::after {
      ${navItemActiveStyles}
    }
  }

  @media ${media.desktopSm} {
    &:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
`;

export const Nav = styled.ul<NavStyledProps>`
  display: flex;
  flex-direction: column;

  ${({ isAlt, theme }) =>
    isAlt &&
    css`
      ${NavLink} {
        padding: 0.25rem 0;
        color: ${theme.colors.additional.dark};
      }
      @media ${media.tablet} {
        ${NavItem}:not(:first-child) {
          margin-left: 3rem;
        }
        ${NavItem}::after {
          background-color: ${theme.colors.additional.dark};
        }
      }
    `}

  @media ${media.maxTablet} {
    background: ${({ theme }) => theme.colors.additional.accentGray3};
  }

  @media ${media.tablet} {
    flex-direction: row;
  }
`;
