import React from 'react';

import styled, { css } from 'styled-components';
import Link from 'gatsby-link';
import { rgba } from 'polished';

import media from '../../../common/MediaQueries';
import { StyledLinkBaseProps } from './types';
import { transition } from '../../../common/mixins';
import { LinkMode } from './enums';

export const baseLinkStyles = css`
  border: none;
  padding: 0;
  background-color: initial;
`;

export const plainLinkStyles = css`
  outline: none;
`;

export const linkWithArrowRightStyles = css`
  display: inline-flex;
  align-items: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  svg {
    font-size: 1rem;
    margin-left: 1rem;
    ${transition('transform')}
  }

  @media ${media.tablet} {
    &:hover {
      svg {
        transform: translate(1rem);
      }
    }
  }
`;

export const underlineLinkStyles = css`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  &::after {
    ${transition('transform')}
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    transform: scale(0);
    background-color: ${({ theme }) => theme.colors.secondary.hover};
  }

  @media ${media.tablet} {
    &:hover {
      &::after {
        transform: scale(1);
      }
    }
  }
`;

export const buttonLinkStyles = css`
  display: inline-flex;
  justify-content: center;
  padding: 1rem 1.5rem;
  border-radius: 100vh;

  ${transition('background-color')}
  ${({ theme }) => theme.shadows.outline};

  color: ${({ theme }) => theme.colors.neutral.neutral10};
`;

export const borderLinkStyles = css`
  ${buttonLinkStyles}
  background-color: transparent;
  border: 0.125rem solid ${({ theme }) => theme.colors.neutral.neutral10};

  @media ${media.tablet} {
    &:hover {
      background-color: ${({ theme }) => rgba(theme.colors.neutral.neutral10, 0.15)};
    }
  }
`;

export const buttonLinkCommonStyles = (
  disabled?: boolean,
  mode: LinkMode = LinkMode.Default,
  hasFullWidth?: boolean,
  hasOverlayMask?: boolean
) => css`
  background-color: ${({ theme }) =>
    mode === LinkMode.HasDarkBackground
      ? theme.colors.primary.hover
      : theme.colors.secondary.hover};

  @media ${media.tablet} {
    &:hover {
      background-color: ${({ theme }) =>
        mode === LinkMode.HasDarkBackground
          ? theme.colors.primary.pressed
          : theme.colors.secondary.pressed};
    }
  }
`;

export const linkCommonStyles = (
  disabled?: boolean,
  mode?: LinkMode,
  hasFullWidth?: boolean,
  hasOverlayMask?: boolean
) => css`
  ${(mode === LinkMode.WithArrowRight && linkWithArrowRightStyles) ||
  (mode === LinkMode.HasDarkBackground && buttonLinkStyles) ||
  (mode === LinkMode.HasBorder && borderLinkStyles) ||
  (mode === LinkMode.HasPlainLook && plainLinkStyles) ||
  (mode === LinkMode.HasUnderline && underlineLinkStyles) ||
  (mode === LinkMode.Default && buttonLinkStyles)};

  ${(mode === LinkMode.HasDarkBackground || mode === LinkMode.Default) &&
  css`
    ${buttonLinkCommonStyles(disabled, mode, hasFullWidth, hasOverlayMask)}
  `};

  ${hasFullWidth &&
  css`
    width: 100%;
  `};

  ${disabled &&
  css`
    pointer-events: none;
  `};

  &:before {
    ${hasOverlayMask &&
    css`
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    `}
  }
`;

export const LinkBase = styled<React.FC<StyledLinkBaseProps>>(
  ({ isExternal, to, children, externalStyles: _, disabled, ...rest }) =>
    isExternal ? (
      <a
        {...{ disabled, ...rest }}
        href={to}
        target="_blank"
        rel="noreferrer noopener"
        aria-disabled={disabled}
      >
        {children}
      </a>
    ) : (
      <Link
        {...{
          to,
          disabled,
          ...rest,
        }}
        aria-disabled={disabled}
      >
        {children}
      </Link>
    )
)`
  ${({ externalStyles }) => externalStyles};
`;
