import { space, typography, variant } from 'styled-system';

import styled from 'styled-components';
import text from '../../../theme/text';

import { TypographyProps } from './types';

export const Typography = styled.p<TypographyProps>`
  ${typography};
  ${space};
  ${variant({
    scale: 'text',
    variants: text,
  })};
`;

// TODO - example of attrs to overwrite
// export const Text = styled(Typography).attrs({
//   fontWeights: 'regula',
//   forwardedAs: 'span',
// })``;
