import styled, { css } from 'styled-components';

import media from '../../../common/MediaQueries';
import { transition } from '../../../common/mixins';
import { InnerMedium } from '../../styles/Grid/styles';
import Link from '../../atoms/Link';
import { BurgerOpenIcon } from '../../molecules/Burger/styles';
import { NavLink } from '../../molecules/Nav/styles';
import { DropdownHead } from '../../molecules/Dropdown/styles';

import { HeaderDropdownStyledProps, HeaderStyledProps } from './types';
import { HeaderMode } from './enums';

export const HeaderInner = styled(InnerMedium)``;

export const HeaderContent = styled.div`
  ${({ theme }) => theme.shadows.border};
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  height: 6.25rem;
  @media ${media.tablet} {
    display: flex;
    justify-content: space-between;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const HeaderTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: ${({ theme }) => theme.zIndexes.headerTop};
  @media ${media.tablet} {
    margin-right: 2rem;
    width: auto;
  }
`;

export const HeaderLogo = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral.neutral10};

  svg {
    ${transition()}
    font-size: 3.75rem;
    width: 2.13em;
  }

  @media ${media.tablet} {
    color: ${({ theme }) => theme.colors.neutral.neutral10};
    &:hover svg {
      opacity: 0.8;
    }
  }
`;

export const HeaderBurger = styled.div`
  display: inline-flex;
  @media ${media.tablet} {
    display: none;
  }
`;

export const HeaderNavBox = styled.div`
  @media ${media.maxTablet} {
    ${transition('all', '200ms')}
    transition-property: max-height, opacity;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 6.25rem;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${({ theme }) => theme.colors.neutral.neutral20};
    padding: 0;
    opacity: 0;
    max-height: 0;
    z-index: ${({ theme }) => theme.zIndexes.headerNav};
    overflow: hidden;
  }
  @media ${media.tablet} {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const HeaderNav = styled.nav`
  @media ${media.maxTablet} {
    max-height: calc(100% - 7.75rem);
    overflow: auto;
  }
`;

export const HeaderDropdown = styled.div<HeaderDropdownStyledProps>`
  @media ${media.maxTablet} {
    position: absolute;
    top: -4.75rem;
    right: 4rem;
  }
  @media ${media.tablet} {
    margin-left: 0.25rem;
  }
  @media ${media.desktopSm} {
    margin-left: 1.5rem;
  }
  @media ${media.desktopLg} {
    margin-left: 2rem;
  }

  ${({ hide }) =>
    hide &&
    css`
      visibility: hidden;
    `}
`;

export const HeaderAction = styled.div`
  height: 3.25rem;

  @media ${media.maxTablet} {
    display: flex;
    justify-content: center;
  }

  @media ${media.tablet} {
    margin-left: 0.25rem;
  }

  @media ${media.desktopSm} {
    margin-left: 1.5rem;
  }

  @media ${media.desktopLg} {
    margin-left: 2rem;
  }
`;

export const HeaderStickyAction = styled.div`
  ${transition('all', '400ms')}
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: 0;
  background-color: ${({ theme }) => theme.colors.neutral.neutral20};
  padding: 1.25rem;
  transform: translateY(100%);
  opacity: 0;
  z-index: ${({ theme }) => theme.zIndexes.headerAction};
  height: 5.75rem;
`;

export const HeaderActionLink = styled(Link)`
  white-space: nowrap;
  @media ${media.maxTablet} {
    width: 100%;
  }
`;

export const Header = styled.header<HeaderStyledProps>`
  ${transition('all', '300ms')}
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 6.25rem;
  z-index: ${({ theme }) => theme.zIndexes.header};

  background: ${({ theme, mode }) =>
    (mode === HeaderMode.Default && 'linear-gradient(0deg, transparent, rgba(10, 10, 10, 0.2))') ||
    (mode === HeaderMode.Grey && theme.colors.transparent.main) ||
    (mode === HeaderMode.White && theme.colors.transparent.secondary) ||
    (mode === HeaderMode.LightBlue && theme.colors.transparent.accent)};

  ${DropdownHead},
  ${NavLink} {
    @media ${media.tablet} {
      color: ${({ theme, mode }) =>
        (mode === HeaderMode.White && theme.colors.additional.accentGray4) ||
        (mode === HeaderMode.LightBlue && theme.colors.additional.accentGray4)};
    }
  }

  ${BurgerOpenIcon},
  ${HeaderLogo} {
    color: ${({ theme, mode }) =>
      (mode === HeaderMode.White && theme.colors.additional.accentGray4) ||
      (mode === HeaderMode.LightBlue && theme.colors.additional.accentGray4)};
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      @media ${media.maxTablet} {
        background: ${({ theme }) => theme.colors.neutral.neutral20};
        ${HeaderNavBox} {
          opacity: 1;
          max-height: 100%;
          overflow: initial;
          padding: 0 1.5rem 2.5rem 1.5rem;
        }
        ${HeaderLogo} {
          color: ${({ theme }) => theme.colors.secondary.hover};
        }
      }
    `}

  ${({ isPageScrolled }) =>
    isPageScrolled &&
    css`
      transform: translateY(-100%);
      opacity: 0;
      ~ ${HeaderStickyAction} {
        transform: translateY(0);
        opacity: 1;
      }
    `}
`;
