import React from 'react';
import styled, { css } from 'styled-components';

import { StyledButtonBaseProps } from './types';
import { baseLinkStyles, linkCommonStyles } from '../Link/styles';
import { LinkMode } from '../Link/enums';

export const Button = styled.button`
  ${baseLinkStyles};
`;

export const buttonCommonStyles = (
  disabled?: boolean,
  mode?: LinkMode,
  hasFullWidth?: boolean,
  hasOverlayMask?: boolean
) => css`
  ${linkCommonStyles(disabled, mode, hasFullWidth, hasOverlayMask)}
`;

export const ButtonBase = styled<React.FC<StyledButtonBaseProps>>(
  ({ children, externalStyles, disabled, ...rest }) => (
    <Button
      {...{
        disabled,
        ...rest,
      }}
      aria-disabled={disabled}
    >
      {children}
    </Button>
  )
)`
  ${({ externalStyles }) => externalStyles};
`;
