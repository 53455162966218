import { css } from 'styled-components';

import { transition } from '../../common/mixins';

export const LogoStyles = css`
  display: flex;
  align-items: center;

  svg {
    ${transition()}
    font-size: 3.75rem;
    width: 2.13em;
  }
`;
