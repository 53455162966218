import { useMemo } from 'react';

import { getHref, getImage } from '../utils/sanityTypes';
import { LayoutProps, UseGlobalSettingsReturnType } from './types';

import { NavItemsProps } from '../components/molecules/Nav/types';
import { SocialItemProps } from '../components/molecules/Socials/types';
import { FooterContactItemProps } from '../components/organisms/Footer/types';
import { HeaderMode } from '../components/organisms/Header/enums';
import { RichTextContentProps } from '../components/molecules/RichText/types';
import { SocialMode } from '../components/molecules/Socials/enums';

export const useGlobalSettings = (data: LayoutProps['data']): UseGlobalSettingsReturnType => {
  const { sanityFooter, sanityHeader, sanityCookieInfo, sanityPromoBanner } = data ?? {};

  return useMemo(() => {
    // FOOTER
    const {
      logoUrl: footerLogoUrl,
      menu: footerMenu,
      extractedButton: footerExtractedButton,
      terms: footerTerms,
      location: footerLocation,
      contact: footerContact,
      socials: footerSocials,
      socialsLabel: footerSocialsLabel = '',
      copyrights: footerCopyrights = '',
    } = sanityFooter ?? {};

    // Header
    const {
      logoUrl: headerLogoUrl,
      menu: headerMenu,
      extractedButton: headerExtractedButton,
    } = sanityHeader ?? {};

    // Cookie Information
    const {
      title: cookieInfoTitle,
      desc: cookieInfoDesc,
      label: cookieInfoLabel,
    } = sanityCookieInfo ?? {};

    // Promo Banner
    const {
      title: promoBannerTitle,
      desc: promoBannerDesc,
      isActive: promoBannerIsActive,
      button: promoBannerButton,
      image: promoBannerImage,
    } = sanityPromoBanner ?? {};

    return {
      footer: {
        logoUrl: {
          to: getHref(footerLogoUrl),
        },
        terms: {
          link: getHref(footerTerms?.link),
          label: footerTerms?.buttonLabel ?? '',
        },
        extractedButton: {
          link: getHref(footerExtractedButton?.link),
          label: footerExtractedButton?.buttonLabel ?? '',
        },
        location: {
          title: footerLocation?.title ?? '',
          desc: (footerLocation?.desc ?? undefined) as unknown as RichTextContentProps,
        },
        menu: (footerMenu ?? []).map<NavItemsProps>((menuItem) => {
          const { _key = '', link, buttonLabel: label = '' } = menuItem ?? {};

          return {
            key: _key,
            label,
            link: {
              to: getHref(link),
            },
          };
        }),
        contact: (footerContact ?? []).map<FooterContactItemProps>((singleContact) => {
          const { _key = '', title, desc } = singleContact ?? {};

          return {
            key: _key,
            title: title ?? '',
            desc: (desc ?? undefined) as unknown as RichTextContentProps,
          };
        }),
        socials: (footerSocials ?? []).map<SocialItemProps>((socialLink) => {
          const { _key = '', link, iconMode } = socialLink ?? {};

          return {
            key: _key,
            iconMode: (iconMode ?? '') as SocialMode,
            to: getHref(link),
          };
        }),
        socialsLabel: footerSocialsLabel,
        copyrights: footerCopyrights,
      },
      header: {
        mode: HeaderMode.Grey,
        logoUrl: {
          to: getHref(headerLogoUrl),
        },
        menu: (headerMenu ?? []).map<NavItemsProps>((menuItem) => {
          const { _key = '', link, buttonLabel: label = '' } = menuItem ?? {};

          return {
            key: _key,
            label,
            link: {
              to: getHref(link),
            },
          };
        }),
        extractedButton: {
          link: getHref(headerExtractedButton?.link),
          label: headerExtractedButton?.buttonLabel ?? '',
        },
      },
      cookieInfo: sanityCookieInfo
        ? {
            title: cookieInfoTitle ?? '',
            desc: (cookieInfoDesc ?? undefined) as unknown as RichTextContentProps,
            label: cookieInfoLabel ?? '',
          }
        : undefined,
      promoBanner: sanityPromoBanner
        ? {
            title: promoBannerTitle ?? '',
            desc: (promoBannerDesc ?? undefined) as unknown as RichTextContentProps,
            image: getImage(promoBannerImage),
            isActive: promoBannerIsActive ?? false,
            button: {
              to: getHref(promoBannerButton?.link),
              label: promoBannerButton?.buttonLabel ?? '',
            },
          }
        : undefined,
    };
  }, [sanityFooter, sanityHeader, sanityCookieInfo, sanityPromoBanner]);
};
