import React from 'react';
import { GatsbyBrowser } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { CookiesProvider } from 'react-cookie';

import theme from '../theme';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <ThemeProvider {...{ theme }}>
    <CookiesProvider>{element}</CookiesProvider>
  </ThemeProvider>
);
