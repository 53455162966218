import styled, { css } from 'styled-components';
import { m } from 'framer-motion';

import { Inner } from '../../styles/Grid/styles';
import Typography from '../../styles/Typography';
import Button from '../../atoms/Button';
import { transition } from '../../../common/mixins';

export const CookieBar = styled(m.div)`
  ${transition('all', '280ms')}
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  opacity: 0;
  transform: translateY(100%);
  background-color: ${({ theme }) => theme.colors.neutral.neutral10};
  ${({ theme }) => theme.shadows.banner};
`;

export const CookieBarInner = styled(Inner)`
  padding: 2.5rem;
`;

export const CookieBarTitle = styled(Typography)``;

export const CookieBarDesc = styled(Typography)`
  margin-top: 2rem;
  color: ${({ theme }) => theme.colors.additional.accentGray2};
`;

export const CookieBarAction = styled(Button)`
  margin-top: 0.75rem;
`;
