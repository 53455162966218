import React from 'react';
import { CSSProperties } from 'styled-components';

import Typography from '../../../../styles/Typography';
import { BlockSerializerProps } from './types';
import { mergeTextAlignProp } from './utils';

const Block: React.FC<BlockSerializerProps> = ({ node, children, additionalProps }) => {
  const variant = node?.style === 'normal' ? 'textL' : node?.style || 'textL';

  const alignmentMarkDef = node?.markDefs?.find((markDef) => markDef.textAlignment);

  const blockTextAlign = alignmentMarkDef?.textAlignment as CSSProperties['textAlign'];
  const textAlign = mergeTextAlignProp(blockTextAlign, additionalProps.textAlign);

  return <Typography {...{ variant, textAlign }}>{children}</Typography>;
};

export default Block;
