import React from 'react';
import { useLocation } from '@reach/router';

import { LinkMode } from '../../atoms/Link/enums';
import * as Styled from './styles';
import { NavProps } from './types';

const Nav: React.FC<NavProps> = ({ items, onClick, isAlt }) => {
  const { pathname } = useLocation();

  return (
    <Styled.Nav {...{ isAlt }} role="menu">
      {items.map(({ key, label, link: { to } }) => (
        <Styled.NavItem isActive={pathname === to} {...{ key }} role="none">
          <Styled.NavLink mode={LinkMode.HasPlainLook} role="menuitem" {...{ to, onClick }}>
            <Styled.NavTitle variant="navTitle">{label}</Styled.NavTitle>
          </Styled.NavLink>
        </Styled.NavItem>
      ))}
    </Styled.Nav>
  );
};

export default Nav;
