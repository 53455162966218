import styled, { css } from 'styled-components';

import Typography from '../../styles/Typography';
import { transition } from '../../../common/mixins';
import media from '../../../common/MediaQueries';
import { DropdownStyledProps, DropdownListItemStyledProps } from './types';
import Link from '../../atoms/Link';

export const DropdownHead = styled.div`
  ${transition()}
  display: flex;
  justify-content: center;
  width: 7rem;
  padding: 1rem 0;
`;

export const DropdownBody = styled.div`
  ${transition()}
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
`;

export const DropdownLabel = styled(Typography)``;

export const DropdownIcon = styled.div`
  ${transition()}
  display: flex;
  align-items: center;
  margin-left: 0.75rem;
`;

export const DropdownList = styled.ul`
  background: ${({ theme }) => theme.colors.additional.accentBlack1};
  border: 1px solid ${({ theme }) => theme.colors.secondary.hover};
  border-radius: 0.25rem;
`;

export const DropdownTick = styled.span`
  display: inline-flex;
  height: 1rem;
  width: 1rem;
  flex-shrink: 0;
  border-radius: 50%;
  margin-left: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.neutral.neutral10};
  background: transparent;

  svg {
    font-size: 1rem;
  }
`;

export const DropdownListItem = styled.li<DropdownListItemStyledProps>`
  ${transition()}
  ${({ theme }) => theme.shadows.main};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.625rem 1rem 0.625rem 1.25rem;

  ${DropdownLabel} {
    width: 100%;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      ${DropdownTick} {
        background: ${({ theme }) => theme.colors.neutral.neutral10};
        color: ${({ theme }) => theme.colors.neutral.neutral100};
      }
    `}

  @media ${media.tablet} {
    &:hover {
      opacity: 0.6;
    }
  }
`;

export const DropdownLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const selectedStyles = css`
  ${DropdownHead} {
    opacity: 0.6;
  }
  ${DropdownIcon} {
    transform: rotate(180deg);
  }
  ${DropdownBody} {
    max-height: 62.5rem;
    width: fit-content;
    opacity: 1;
  }
`;

export const Dropdown = styled.div<DropdownStyledProps>`
  position: relative;
  color: ${({ theme }) => theme.colors.neutral.neutral10};
  cursor: pointer;

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${selectedStyles}
    `}

  @media ${media.maxTablet} {
    ${DropdownHead} {
      color: ${({ theme }) => theme.colors.additional.dark};
    }
  }

  @media ${media.tablet} {
    &:hover {
      ${selectedStyles}
    }
  }
`;
