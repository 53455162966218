import React, { useCallback } from 'react';

import { IconArrowRight } from '../../../assets/svg';
import * as Styled from './styles';
import { ButtonProps } from './types';
import ButtonBase from './buttonBase';
import { LinkMode } from '../Link/enums';

const Button: React.FC<ButtonProps> = ({
  children,
  mode = LinkMode.Default,
  hasFullWidth,
  hasOverlayMask,
  ...rest
}) => {
  const makeStyles = useCallback(
    ({ disabled }) => Styled.buttonCommonStyles(disabled, mode, hasFullWidth, hasOverlayMask),
    [mode, hasFullWidth, hasOverlayMask]
  );

  return (
    <ButtonBase makeExternalStyles={makeStyles} {...rest}>
      {children} {mode === LinkMode.WithArrowRight && <IconArrowRight />}
    </ButtonBase>
  );
};

export default Button;
