export default {
  textS: {
    fontSize: '0.75rem', // 12px
    lineHeight: '1.3',
  },
  textM: {
    fontSize: '0.875rem', // 14px
    lineHeight: '1.4',
  },
  textL: {
    fontSize: '1rem', // 16px
    lineHeight: '1.5',
  },
  headingS: {
    fontSize: '1.25rem', // 20px
    lineHeight: '1.4',
  },
  headingM: {
    fontSize: '1.75rem', // 28px
    lineHeight: '1.3',
  },
  headingL: {
    fontSize: '2.25rem', // 36px
    lineHeight: '1.2',
  },
  headingXL: {
    fontSize: '3rem', // 48px
    lineHeight: '1.25',
  },
  headingXXL: {
    fontSize: '4rem', // 64px
    lineHeight: '1.25',
  },
  headingXXXL: {
    fontSize: ['6rem', '9rem'],
    lineHeight: '1.1',
  },
  navTitle: {
    fontSize: ['1.75rem', '', '0.875rem'], // 28 // 14px
    lineHeight: ['1.28', '', '1.4'],
  },
};
