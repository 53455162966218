import React, { useCallback, useState } from 'react';

export const useCopyClick = () => {
  const [isCopied, setIsCopied] = useState(false);

  const onClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    if (typeof window === 'undefined') return;
    e.preventDefault();
    navigator.clipboard.writeText(`${window.location}`);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  }, []);

  return { isCopied, onClick };
};
