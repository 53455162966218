import { m } from 'framer-motion';
import styled from 'styled-components';

import media from '../../../common/MediaQueries';
import { transition } from '../../../common/mixins';
import Button from '../../atoms/Button';
import Link from '../../atoms/Link';
import { FlexBox, Inner } from '../../styles/Grid/styles';
import Typography from '../../styles/Typography';

export const PromoBanner = styled(m.div)`
  ${transition('all', '280ms')}
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  padding: 1rem 0;
  opacity: 0;
  transform: translateY(100%);
  background-color: ${({ theme }) => theme.colors.additional.background};
  ${({ theme }) => theme.shadows.banner};
`;

export const PromoBannerInner = styled(Inner)``;

export const PromoBannerContentBox = styled(FlexBox)`
  flex-direction: column;
  @media ${media.phone} {
    position: relative;
    flex-direction: row;
    padding-right: 1.75rem;
  }
`;

export const PromoBannerMedia = styled.div`
  height: 9rem;
  overflow: hidden;
  img {
    border-radius: 0.25rem;
  }
  @media ${media.phone} {
    height: auto;
    width: 17.5rem;
    min-width: 17.5rem;
    margin-right: 2rem;
  }
  @media ${media.tablet} {
    margin-right: 4.5rem;
  }
`;

export const PromoBannerContent = styled.div`
  flex-grow: 1;
  margin-top: 1rem;
  max-width: 40rem;
`;

export const PromoBannerTitle = styled(Typography)``;

export const PromoBannerSubitle = styled(Typography)`
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.additional.accentGray2};
`;

export const PromoBannerAction = styled(Link)`
  margin-top: 0.5rem;
`;

export const PromoBannerClose = styled(Button)`
  display: inline-flex;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
  font-size: 1.75rem;
  color: ${({ theme }) => theme.colors.additional.accentGray4};
  @media ${media.phone} {
    ${transition()}
    position: absolute;
    right: 0;
    top: 0.5rem;
    margin-bottom: 0;
    &:hover {
      opacity: 0.9;
    }
  }
`;
