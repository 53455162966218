import React from 'react';

import { ColorSerializerProps } from './types';

const Color: React.FC<ColorSerializerProps> = (props) => {
  const { mark, children } = props;

  const { hex } = mark ?? {};

  return (
    <span
      style={{
        color: hex,
      }}
    >
      {children}
    </span>
  );
};

export default Color;
