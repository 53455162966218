import styled from 'styled-components';

import { RichTextContainerProps } from './types';
import { transition } from '../../../common/mixins';
import media from '../../../common/MediaQueries';

export const RichTextContainer = styled.div<RichTextContainerProps>`
  letter-spacing: -0.011em;
  color: ${({ theme, hasDarkBackground }) =>
    hasDarkBackground ? 'inherit' : theme.colors.additional.accentGray2};
  ${({ theme }) => theme.textL};

  & > div:not([class]) > *:not(:first-child),
  & > *:not(:first-child) {
    margin-top: 1.5rem;
  }

  .decor:not(.block) {
    display: inline;
  }

  figure {
    margin: 0;
  }

  & > div:not([class]) > figure:not(:first-child),
  & > div:not([class]) > h1:not(:first-child),
  & > div:not([class]) > h2:not(:first-child),
  & > div:not([class]) > h3:not(:first-child),
  & > div:not([class]) > h4:not(:first-child),
  & > div:not([class]) > h5:not(:first-child),
  & > figure:not(:first-child),
  & > h1:not(:first-child),
  & > h2:not(:first-child),
  & > h3:not(:first-child),
  & > h4:not(:first-child),
  & > h5:not(:first-child) {
    margin-top: 2.5rem;
  }

  h1 {
    ${({ theme }) => theme.headingXL};
  }

  h2 {
    ${({ theme }) => theme.headingL};
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    ${({ theme }) => theme.headingM};
  }

  h5 {
    ${({ theme }) => theme.headingS};
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    letter-spacing: -0.017em;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: ${({ theme, hasDarkBackground }) =>
      hasDarkBackground ? 'inherit' : theme.colors.additional.dark};
  }

  figcaption {
    margin-top: 0.5rem;
  }

  strong {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }

  ul li {
    position: relative;
    padding-left: 1.5rem;

    &::before {
      position: absolute;
      display: block;
      content: '';
      height: 0.875rem;
      width: 0.875rem;
      left: 0;
      top: 0.375rem;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.secondary.hover};
      background-image: url('/svg/icons/tick-circle.svg');
      background-size: contain;
      background-position: 50% 50%;
    }
  }

  ol {
    padding-left: 1.125rem;
  }

  ol li {
    list-style: decimal;
  }

  li + li {
    margin-top: 1rem;
  }

  img {
    &.is-left {
      margin-right: auto;
    }
    &.is-right {
      margin-left: auto;
    }
    &.is-center {
      margin: 0 auto;
    }
  }

  a {
    ${transition()}
    text-decoration: none;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    /* color: ${({ theme }) => theme.colors.secondary.hover}; Temporary disabled */

    background-image: linear-gradient(currentColor, currentColor);
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: 100% 1px;

    &:focus,
    &:hover {
      background-size: 0% 1px;
    }
  }

  @media ${media.phone} {
    h1 {
      ${({ theme }) => theme.headingXXL};
    }
    h2 {
      ${({ theme }) => theme.headingXL};
    }
    h3 {
      ${({ theme }) => theme.headingL};
    }
  }
`;

export const CodeBlock = styled.code`
  display: block;
  white-space: pre;
  padding: 1em;
  overflow-x: auto;
  border-radius: 0.5em;
`;
