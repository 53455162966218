import styled from 'styled-components';

const ImageStyled = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;

    .object-fit-polyfill {
      position: static !important;
    }
  }
`;

export { ImageStyled };
