import React, { useCallback } from 'react';

import * as Styled from './styles';
import { LinkProps } from './types';
import { LinkMode } from './enums';
import LinkBase from './linkBase';
import { IconArrowRight } from '../../../assets/svg';

const Link: React.FC<LinkProps> = ({
  children,
  mode = LinkMode.Default,
  hasFullWidth,
  hasOverlayMask,
  ...rest
}) => {
  const makeStyles = useCallback(
    ({ disabled }) => Styled.linkCommonStyles(disabled, mode, hasFullWidth, hasOverlayMask),
    [mode, hasFullWidth, hasOverlayMask]
  );

  return (
    <LinkBase makeExternalStyles={makeStyles} {...rest}>
      {children} {mode === LinkMode.WithArrowRight && <IconArrowRight />}
    </LinkBase>
  );
};

export default Link;
