import React, { useMemo } from 'react';

import * as Styled from './styles';
import { ButtonBaseProps } from './types';
import Typography from '../../styles/Typography';

export const ButtonBase: React.FC<ButtonBaseProps> = ({
  makeExternalStyles,
  disabled,
  children,
  ...rest
}) => {
  const content =
    typeof children === 'string' ? (
      <Typography as="span" variant="textM">
        {children}
      </Typography>
    ) : (
      children
    );

  const externalStyles = useMemo(
    () => makeExternalStyles({ disabled: !!disabled }),
    [disabled, makeExternalStyles]
  );

  return (
    <Styled.ButtonBase
      {...{
        disabled,
        externalStyles,
        ...rest,
      }}
    >
      {content}
    </Styled.ButtonBase>
  );
};

export default ButtonBase;
