export default {
  colors: {
    neutral: {
      neutral10: '#ffffff',
      neutral20: '#F5F5F5',
      neutral30: '#EDEDED',
      neutral40: '#E0E0E0',
      neutral50: '#C2C2C2',
      neutral60: '#9E9E9E',
      neutral70: '#757575',
      neutral80: '#616161',
      neutral90: '#424242',
      neutral100: '#0A0A0A',
    },
    primary: {
      main: '#CFD9E3',
      surface: '#E8EFF7',
      border: '#E9F3FD',
      hover: '#6BA5C2',
      pressed: '#57869D',
      focus: 'rgba(207, 217, 227, 0.2)',
    },
    secondary: {
      hover: '#324843',
      pressed: '#2B403B',
    },
    additional: {
      accentGray1: '#E6E6E2',
      accentGray2: '#6E6F72',
      accentGray3: '#F3F3F3',
      accentGray4: '#292D32',
      accentBlack1: '#0F0F0F',
      background: '#FEFEFE',
      dark: '#1D1D1D',
      orange: '#E1885E',
    },
    danger: {
      main: '#E00000',
      surface: '#FFECEC',
      border: '#FFD7D7',
      hover: '#A20000',
      pressed: '#630000',
    },
    warning: {
      surface: '#FFF2E7',
      border: '#FFCA99',
      pressed: '#653508',
    },
    success: {
      main: '#008243',
      surface: '#EBF5F0',
      border: '#B3DAC7',
      hover: '#006836',
    },
    info: {
      main: '#0B5CD7',
      surface: '#ECF2FC',
      border: '#B6CFF3',
    },
    transparent: {
      main: 'rgba(0, 0, 0, 60%)',
      secondary: 'rgba(255, 255, 255, 50%)',
      accent: 'rgba(232, 239, 246, 80%)',
    },
  },
};
